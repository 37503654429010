/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


/* Styles for a light-themed tooltip */
.custom-tooltip.light-theme {
    background-color: #ffffff;
    color: #000000;
    text-align: center;
  }
  
  /* Styles for a dark-themed tooltip */
  .custom-tooltip.dark-theme {
    background-color: #130f0f;
    color: #ffffff;
    text-align: center;
  }

  body {
    font-family: 'Comfortaa', sans-serif;
}

.notyf__toast--error {
  background-color: red !important; 
  color: white !important; 
  border-radius: 5px; 
  font-weight: bold; 
}

.notyf__toast--success {
  background-color: #28a745 !important; 
  color: white !important;
}
  
  /* Customize further with additional properties */
  .custom-tooltip {
    max-width: 400px;
    font-size: 14px;
    padding: 8px;
  }
  